import Validations from "./validations_controller"

export default class extends Validations {
  static targets = [
    'category',
    'largeImage',
    'thumbnail',
    'imageUploads'
  ]

  connect() {
    super.connect()
  }

  toggleImageUpload() {
    if (this.categoryTarget.value == "misc") {
      this.largeImageTarget.disabled = true
      this.thumbnailTarget.disabled = true
      this.imageUploadsTarget.style.display = "none"
    } else {
      this.largeImageTarget.disabled = false
      this.thumbnailTarget.disabled = false
      this.imageUploadsTarget.style.display = "block"
    }
  }
}