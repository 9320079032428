import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'name',
    'nameError',
    'email',
    'emailError',
    'phone',
    'phoneError',
    'address',
    'addressError',
    'city',
    'cityError',
    'zip',
    'zipError',
    'state',
    'stateError',
  ]

  connect() {
    this.errors = []
    this.ERR = {
      NAME: "name",
      EMAIL: "email",
      PHONE: "phone",
      ADDRESS: "address",
      CITY: "city",
      ZIP: "zip",
      STATE: "state",
    }
    this.MESSAGE = {
      NAME: {
        EMPTY: "A name is required",
        FORMAT: "Name format is invalid"
      },
      EMAIL: {
        EMPTY: "An email is required",
        FORMAT: "Email format is invalid"
      },
      PHONE: {
        EMPTY: "A phone Number is required",
        LENGTH: "Please enter 10 digits"
      },
      ADDRESS: {
        EMPTY: "Address is required"
      },
      CITY: {
        EMPTY: "City is required"
      },
      ZIP: {
        EMPTY: "Zip code is required"
      },
      STATE: {
        EMPTY: "Please select a state"
      }
    }
    this.REGEX = {
      NAME: /^[a-z ,.'-]+$/i,
      PHONE: /^(\d{3})(\d{3})(\d{4})$/,
      EMAIL: /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i,
      DECIMAL: /^[0-9.]+$/,
      INTEGER: /^[0-9]+$/,
      TEXT: /^[a-z\s]+$/i,
      GROUP_NUMBER: /^[a-z0-9]+/i
    }
  }

  validateName() {
    this.checkValue(
      this.nameTarget.value,
      this.nameErrorTarget,
      this.ERR.NAME,
      this.MESSAGE.NAME,
      this.REGEX.NAME
    )
  }

  validateEmail() {
    this.checkValue(
      this.emailTarget.value,
      this.emailErrorTarget,
      this.ERR.EMAIL,
      this.MESSAGE.EMAIL,
      this.REGEX.EMAIL
    )
  }

  // TODO: update to work with any value passed for forms with multiple phone numbers
  validatePhone() {
    let _phone = this.phoneTarget.value
    if (this.isEmpty(_phone)) {
      this.setError(this.ERR.PHONE, this.phoneErrorTarget, this.MESSAGE.PHONE.EMPTY)
    } else if (this.formatIsNotValid(_phone, this.REGEX.PHONE)) {

      this.onlyAllowNumbers(_phone, this.phoneTarget)

      if (_phone.length < 10) {
        this.setError(this.ERR.PHONE, this.phoneErrorTarget, this.MESSAGE.PHONE.LENGTH)
      } else if (_phone.length > 10) {
        // Remove any number after 10 digits are typed
        this.phoneTarget.value = _phone.slice(0, -1)
      }
    } else {
      this.removeError(this.ERR.PHONE, this.phoneErrorTarget)
    }
  }

  validateAddress() {
    this.checkValue(
      this.addressTarget.value,
      this.addressErrorTarget,
      this.ERR.ADDRESS,
      this.MESSAGE.ADDRESS
    )
  }

  validateCity() {
    this.checkValue(
      this.cityTarget.value,
      this.cityErrorTarget,
      this.ERR.CITY,
      this.MESSAGE.CITY
    )
  }

  validateZip() {
    this.checkValue(
      this.zipTarget.value,
      this.zipErrorTarget,
      this.ERR.ZIP,
      this.MESSAGE.ZIP
    )
  }

  validateState() {
    this.checkValue(
      this.stateTarget.value,
      this.stateErrorTarget,
      this.ERR.STATE,
      this.MESSAGE.STATE
    )
  }

  removeValueFromArray(arr, value) {
    return arr.filter((str) => str == value ? false : true)
  }

  setError(err, errTarget, errMessage) {
    if (!this.errors.includes(err)) {
      this.errors.push(err)
    }
    errTarget.textContent = errMessage
  }

  removeError(err, errTarget) {
    if (this.errors.includes(err)) {
      this.errors = this.removeValueFromArray(this.errors, err)
    }
    errTarget.textContent = ""
  }

  formatIsNotValid(str, pattern) {
    // We are checking that it isn't valid this 
    // will return false if the pattern matches
    if (pattern.test(str)) {
      return false
    }
    return true
  }

  isEmpty(val) {
    if (val.length == 0) {
      return true
    }
    return false
  }

  // Checks last input character
  // removes it if not a number
  onlyAllowNumbers(val, target) {
    if (isNaN(parseInt(val.substr(-1)))) {
      target.value = val.slice(0, -1)
    }
  }

  // Checks the last character typed to see if it's NaN
  // if it's not a number, or the value as a whole
  // doesn't match the regex, it's removed.
  onlyAllowText(val, target) {
    if (!isNaN(val.substr(-1)) || this.formatIsNotValid(val, this.REGEX.TEXT)) {
      target.value = val.slice(0, -1)
    }
  }

  // Checks value based on rules and sets errors.
  // If rules is omitted, this will only
  // check for empty values.
  checkValue(target, errTarget, err, messages, format = "") {
    // If it's a string we need to trim whitespace
    if (typeof target == "string") {
      target = target.trim()
    }
    if (this.isEmpty(target)) {
      this.setError(err, errTarget, messages.EMPTY)
    } else if (format.length > 0) {
      if (this.formatIsNotValid(target, format)) {
        this.setError(err, errTarget, messages.FORMAT)
      }
    } else {
      this.removeError(err, errTarget)
    }


  }

  checkForm(e) {
  }
}