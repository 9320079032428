import Validations from "./validations_controller"

export default class extends Validations {
	static targets = [
		"newGroup",
    "existingGroup",
    "groupTypeError",
    "group",
    "groupError",
    "payout",
    "payoutError",
    "groupNumber",
    "groupNumberError"
	]

  connect() {
    super.connect()
    this.ERR.GROUP_TYPE = "groupType"
    this.ERR.GROUP = "group"
    this.ERR.PAYOUT = "payout"
    this.ERR.GROUP_NUMBER = "groupNumber"

    this.MESSAGE.GROUP_TYPE = {
      EMPTY: "Please choose a group type"
    }
    this.MESSAGE.GROUP = {
      EMPTY: "Please provide a group name"
    }
    this.MESSAGE.PAYOUT = {
      EMPTY: "Please provide a payout"
    }
    this.MESSAGE.GROUP_NUMBER = {
      EMPTY: "Please provide a group number"
    }
  }

  validateGroupType() {
    if (!this.newGroupTarget.checked && !this.existingGroupTarget.checked) {
      this.setError(this.ERR.GROUP_TYPE, this.groupTypeErrorTarget, this.MESSAGE.GROUP_TYPE.EMPTY)
    } else {
      this.toggleGroupNumber()
      this.removeError(this.ERR.GROUP_TYPE, this.groupTypeErrorTarget)
    }
  }

  toggleGroupNumber() {
    let input = document.querySelector("#groupNumberInput")
    let div = document.querySelector("#groupNumberDiv")
    if (this.existingGroupTarget.checked) {
      div.style.display = "block"
      input.disabled = false
    } else {
      div.style.display = "none"
      input.disabled = true
    }
  }

  validateGroupNumber() {
    // If existing group is checked
    if (this.existingGroupTarget.checked) {
      this.checkValue(
        this.groupNumberTarget.value,
        this.groupNumberErrorTarget,
        this.ERR.GROUP_NUMBER,
        this.MESSAGE.GROUP_NUMBER,
        this.REGEX.GROUP_NUMBER
      )
    } else {
      this.removeError(this.ERR.GROUP_NUMBER, this.groupNumberErrorTarget)
    }
  }

  validateGroup() {
    this.checkValue(
      this.groupTarget.value, 
      this.groupErrorTarget,
      this.ERR.GROUP,
      this.MESSAGE.GROUP
    )
  }

  validatePayout() {
    this.checkValue(
      this.payoutTarget.value, 
      this.payoutErrorTarget,
      this.ERR.PAYOUT,
      this.MESSAGE.PAYOUT
    )
  }

  checkForm(e) {
    this.validateName()
    this.validateEmail()
    this.validateGroupType()
    this.validateGroup()
    this.validateGroupNumber()
    this.validatePayout()
    if (this.errors.length > 0) {
      e.preventDefault()
      scroll(0, 0)
    }
  }
}