// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require("@rails/ujs").start()
require("turbolinks").start()
const jquery = require("jquery")
window.jQuery = jquery
window.$ = jquery
require("bootstrap")

// Datatables
var dt = require('datatables.net')
window.dt = dt

// stylesheets
import "../stylesheets/application.scss"

// Sweet Alert
import Swal from 'sweetalert2/dist/sweetalert2.js'
window.Swal = Swal

// Toastr
import toastr from 'toastr'
window.toastr = toastr

// Stimulus config
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Admin functions
import * as admin from "../src/admin"
Object.entries(admin).forEach(([name, exported]) => window[name] = exported)
