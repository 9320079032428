import Validations from "./validations_controller"

export default class extends Validations {
	static targets = [
    'personSpoke',
    'personSpokeError',
    'repName',
    'repNameError',
    'problem',
    'problemError'
	]

	connect() {
		super.connect()
    this.ERR.PERSON_SPOKE = "personSpoke"
    this.ERR.REP_NAME = "repName"
    this.ERR.PROBLEM = "problem"

    this.MESSAGE.NAME.EMPTY = "A clinic name is required"
    this.MESSAGE.PERSON_SPOKE = {
      EMPTY: "Please provide a name"
    }
    this.MESSAGE.REP_NAME = {
      EMPTY: "Please provide a name"
    }
    this.MESSAGE.PROBLEM = {
      EMPTY: "Please detail the problem"
    }
	}

  validatePersonSpoke() {
    this.checkValue(
      this.personSpokeTarget.value, 
      this.personSpokeErrorTarget,
      this.ERR.PERSON_SPOKE,
      this.MESSAGE.PERSON_SPOKE
    )
  }

  validateRepName() {
    this.checkValue(
      this.repNameTarget.value, 
      this.repNameErrorTarget,
      this.ERR.REP_NAME,
      this.MESSAGE.REP_NAME
    )
  }

  validateProblem() {
    this.checkValue(
      this.problemTarget.value, 
      this.problemErrorTarget,
      this.ERR.PROBLEM,
      this.MESSAGE.PROBLEM
    )
  }


  checkForm(e) {
    this.validateState()
    this.validateName()
    this.validateAddress()
    this.validateCity()
    this.validateZip()
    this.validatePhone()
    this.validatePersonSpoke()
    this.validateRepName()
    this.validateProblem()
    if (this.errors.length > 0) {
      e.preventDefault()
      scroll(0, 0)
    }
  }
}