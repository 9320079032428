import Validations from "./validations_controller"

export default class extends Validations {
  static targets = [
    "pharmacySelect",
    "pharmacyError",
    "pharmacyInput",
    "personSpoke",
    "personSpokeError",
    "memberName",
    "memberNameError",
    "problem",
    "problemError"
  ]

  connect() {
    super.connect()
    this.ERR.PERSON_SPOKE = "personSpoke"
    this.ERR.MEMBER_NAME = "personSpoke"
    this.ERR.PROBLEM = "problem"
    this.ERR.PHARMACY = "pharmacy"

    this.MESSAGE.PERSON_SPOKE = {
      EMPTY: "Please provide a name"
    }
    this.MESSAGE.MEMBER_NAME = {
      EMPTY: "Please provide a name"
    }
    this.MESSAGE.PROBLEM = {
      EMPTY: "Please detail the problem"
    }
    this.MESSAGE.PHARMACY = {
      EMPTY: "Please provide a pharmacy name"
    }
  }

  validatePharmacy() {
    if (this.pharmacySelectTarget.value == "" && this.pharmacyInputTarget.value == "") {
      this.pharmacySelectTarget.disabled = false
      this.pharmacyInputTarget.disabled = false
      this.setError(this.ERR.PHARMACY, this.pharmacyErrorTarget, this.MESSAGE.PHARMACY.EMPTY)
    } else if (this.pharmacySelectTarget.value == "" && this.pharmacyInputTarget.value != "") {
      // Deactivate Select
      this.pharmacySelectTarget.disabled = true
      this.pharmacyInputTarget.disabled = false
      this.removeError(this.ERR.PHARMACY, this.pharmacyErrorTarget)
    } else if (this.pharmacySelectTarget.value != "" && this.pharmacyInputTarget.value == "") {
      // Deactivate Input
      this.pharmacySelectTarget.disabled = false
      this.pharmacyInputTarget.disabled = true
      this.removeError(this.ERR.PHARMACY, this.pharmacyErrorTarget)
    }
  }

  validatePharmacyInput() {
    this.checkValue(
      this.pharmacyInputTarget.value,
      this.pharmacyErrorTarget,
      this.ERR.PHARMACY,
      this.MESSAGE.PHARMACY
    )
    this.validatePharmacy()
  }

  validatePersonSpoke() {
    this.checkValue(
      this.personSpokeTarget.value,
      this.personSpokeErrorTarget,
      this.ERR.PERSON_SPOKE,
      this.MESSAGE.PERSON_SPOKE
    )
  }

  validateMemberName() {
    this.checkValue(
      this.memberNameTarget.value,
      this.memberNameErrorTarget,
      this.ERR.MEMBER_NAME,
      this.MESSAGE.MEMBER_NAME
    )
  }

  validateProblem() {
    this.checkValue(
      this.problemTarget.value,
      this.problemErrorTarget,
      this.ERR.PROBLEM,
      this.MESSAGE.PROBLEM
    )
  }


  checkForm(e) {
    this.validateState()
    this.validatePharmacy()
    this.validateAddress()
    this.validateCity()
    this.validateZip()
    this.validatePhone()
    this.validatePersonSpoke()
    this.validateMemberName()
    this.validateProblem()
    if (this.errors.length > 0) {
      e.preventDefault()
      scroll(0, 0)
    }
  }
}