import Validations from "./validations_controller"

export default class extends Validations {
	static targets = [
    "pharmacySelect",
    "pharmacyError",
    "pharmacyInput",
    "personSpoke",
    "personSpokeError",
    "patientNameLabel",
    "patientName",
    "patientNameError",
    "patientDOBLabel",
    "patientDOB",
    "patientDOBError",
    "rxNumberLabel",
    "rxNumber",
    "rxNumberError",
    "patientPhone",
    "patientPhoneError",
    "medicationLabel",
    "medication",
    "medicationError",
    "dosageLabel",
    "dosage",
    "dosageError",
    "group",
    "groupError",
    "problem",
    "problemError",
	]

  connect() {
    super.connect()

    // List to hold fields that are conditionally required
    this.not_required = []

    this.ERR.PHARMACY = "pharmacy"
    this.ERR.PERSON_SPOKE = "personSpoke"
    this.ERR.PATIENT_NAME = "patientName"
    this.ERR.PATIENT_DOB = "patientDOB"
    this.ERR.RX_NUMBER = "rxNumber"
    this.ERR.PATIENT_PHONE = "patientPhone"
    this.ERR.MEDICATION = "medication"
    this.ERR.DOSAGE = "dosage"
    this.ERR.GROUP = "group"
    this.ERR.PROBLEM = "problem"

    
    this.MESSAGE.PHARMACY = {
      EMPTY: "Please provide a pharmacy name"
    }
    this.MESSAGE.PERSON_SPOKE = {
      EMPTY: "Please provide a name"
    }
    this.MESSAGE.PATIENT_NAME = {
      EMPTY: "Please provide a name"
    }
    this.MESSAGE.PATIENT_DOB = {
      EMPTY: "Please provide a DOB"
    }
    this.MESSAGE.RX_NUMBER = {
      EMPTY: "Please provide a RX Number"
    }
    this.MESSAGE.MEDICATION = {
      EMPTY: "Please provide a medication"
    }
    this.MESSAGE.DOSAGE = {
      EMPTY: "Please provide a dosage or quantity"
    }
    this.MESSAGE.GROUP = {
      EMPTY: "Please provide a group number"
    }
    this.MESSAGE.PROBLEM = {
      EMPTY: "Please detail the problem"
    }
  }

  validatePharmacy() {
    if (this.pharmacySelectTarget.value == "" && this.pharmacyInputTarget.value == "") {
      this.pharmacySelectTarget.disabled = false
      this.pharmacyInputTarget.disabled = false
      this.setError(this.ERR.PHARMACY, this.pharmacyErrorTarget, this.MESSAGE.PHARMACY.EMPTY)
    } else if (this.pharmacySelectTarget.value == "" && this.pharmacyInputTarget.value != "") {
      // Deactivate Select
      this.pharmacySelectTarget.disabled = true
      this.pharmacyInputTarget.disabled = false
      this.removeError(this.ERR.PHARMACY, this.pharmacyErrorTarget)
    } else if (this.pharmacySelectTarget.value != "" && this.pharmacyInputTarget.value == "") {
      // Deactivate Input
      this.pharmacySelectTarget.disabled = false
      this.pharmacyInputTarget.disabled = true
      this.removeError(this.ERR.PHARMACY, this.pharmacyErrorTarget)
    }
  }

  validatePharmacyInput() {
    this.checkValue(
      this.pharmacyInputTarget.value, 
      this.pharmacyErrorTarget,
      this.ERR.PHARMACY,
      this.MESSAGE.PHARMACY
    )
    this.validatePharmacy()
  }

  validatePersonSpoke() {
    this.checkValue(
      this.personSpokeTarget.value, 
      this.personSpokeErrorTarget,
      this.ERR.PERSON_SPOKE,
      this.MESSAGE.PERSON_SPOKE
    )
  }

  validatePatientName(update_labels = true) {
    // If it's no longer required, remove error and skip check
    if (this.not_required.includes(this.ERR.PATIENT_NAME)) {
      this.removeError(this.ERR.PATIENT_NAME, this.patientNameTarget)
      return
    }
    this.checkValue(
      this.patientNameTarget.value, 
      this.patientNameErrorTarget,
      this.ERR.PATIENT_NAME,
      this.MESSAGE.PATIENT_NAME
    )
    if (update_labels) {
      this.updateLabels()
    }
  }

  validatePatientDOB(update_labels = true) {
    if (this.not_required.includes(this.ERR.PATIENT_DOB)) {
      this.removeError(this.ERR.PATIENT_DOB, this.patientDOBTarget)
      return
    }

    this.checkValue(
      this.patientDOBTarget.value, 
      this.patientDOBErrorTarget,
      this.ERR.PATIENT_DOB,
      this.MESSAGE.PATIENT_DOB
    )

    if (update_labels) {
      this.updateLabels()
    }
  }

  validateRxNumber(update_labels = true) {
    if (this.not_required.includes(this.ERR.RX_NUMBER)) {
      this.removeError(this.ERR.RX_NUMBER, this.rxNumberTarget)
      return
    }
    this.checkValue(
      this.rxNumberTarget.value, 
      this.rxNumberErrorTarget,
      this.ERR.RX_NUMBER,
      this.MESSAGE.RX_NUMBER
    )

    if (update_labels) {  
      this.updateLabels()
    }
  }

  validatePatientPhone() {
    let _phone = this.patientPhoneTarget.value
    if (this.isEmpty(_phone)) {
      this.setError(this.ERR.PATIENT_PHONE, this.patientPhoneErrorTarget, this.MESSAGE.PHONE.EMPTY)
    } else if (this.formatIsNotValid(_phone, this.REGEX.PHONE)) {
      
      this.onlyAllowNumbers(_phone, this.patientPhoneTarget)

      if (_phone.length < 10) {
        this.setError(this.ERR.PATIENT_PHONE, this.patientPhoneErrorTarget, this.MESSAGE.PHONE.LENGTH)
      } else if (_phone.length > 10) {
        // Remove any number after 10 digits are typed
        this.patientPhoneTarget.value = _phone.slice(0, -1)
      } 
    } else {
      this.removeError(this.ERR.PATIENT_PHONE, this.patientPhoneErrorTarget)
    }
  }

  validateMedication(update_labels = true) {
    if (this.not_required.includes(this.ERR.MEDICATION)) {
      this.removeError(this.ERR.MEDICATION, this.medicationTarget)
      return
    }
    this.checkValue(
      this.medicationTarget.value, 
      this.medicationErrorTarget,
      this.ERR.MEDICATION,
      this.MESSAGE.MEDICATION
    )
    if (update_labels) {
      this.updateLabels()
    }
  }

  validateDosage(update_labels = true) {
    if (this.not_required.includes(this.ERR.DOSAGE)) {
      this.removeError(this.ERR.DOSAGE, this.dosageTarget)
      return
    }

    this.checkValue(
      this.dosageTarget.value, 
      this.dosageErrorTarget,
      this.ERR.DOSAGE,
      this.MESSAGE.DOSAGE
    )
    if (update_labels) {
      this.updateLabels()
    }
  }

  validateGroup() {
    this.checkValue(
      this.groupTarget.value, 
      this.groupErrorTarget,
      this.ERR.GROUP,
      this.MESSAGE.GROUP
    )
  }

  validateProblem() {
    this.checkValue(
      this.problemTarget.value,
      this.problemErrorTarget,
      this.ERR.PROBLEM,
      this.MESSAGE.PROBLEM
    )
  }

  updateLabels() {
    let patient_name          = this.patientNameTarget.value
    let patient_name_label    = this.patientNameLabelTarget
    let patient_dob           = this.patientDOBTarget.value
    let patient_dob_label     = this.patientDOBLabelTarget
    let rx_number             = this.rxNumberTarget.value
    let rx_number_label       = this.rxNumberLabelTarget
    let medication_name       = this.medicationTarget.value
    let medication_name_label = this.medicationLabelTarget
    let dosage_quantity       = this.dosageTarget.value
    let dosage_quantity_label = this.dosageLabelTarget

    // Patient name and DOB are filled
    if (patient_dob != "" && patient_name != "" && rx_number == "") {
      rx_number_label.classList.remove("required-field")
      dosage_quantity_label.classList.add("required-field")
      medication_name_label.classList.add("required-field")
      // Update required list
      this.removeRequired(this.ERR.RX_NUMBER)
      this.addRequired(this.ERR.DOSAGE)
      this.addRequired(this.ERR.MEDICATION)
      // Validate values
      this.validateRxNumber(false)
      this.validateDosage(false)
      this.validateDosage(false)
    } else if (patient_dob != "" && patient_name != "" && rx_number != "") {
      rx_number_label.classList.remove("required-field")
      dosage_quantity_label.classList.remove("required-field")
      medication_name_label.classList.remove("required-field")
      // Update required list
      this.removeRequired(this.ERR.RX_NUMBER)
      this.removeRequired(this.ERR.DOSAGE)
      this.removeRequired(this.ERR.MEDICATION)
      // Validate values
      this.validateRxNumber(false)
      this.validateDosage(false)
      this.validateDosage(false)
    } else if (rx_number != "") {
      patient_dob_label.classList.remove("required-field")
      patient_name_label.classList.remove("required-field")
      dosage_quantity_label.classList.remove("required-field")
      medication_name_label.classList.remove("required-field")
      // Update required list
      this.removeRequired(this.ERR.PATIENT_DOB)
      this.removeRequired(this.ERR.PATIENT_NAME)
      this.removeRequired(this.ERR.DOSAGE)
      this.removeRequired(this.ERR.MEDICATION)
      // Validate values
      this.validatePatientDOB(false)
      this.validatePatientName(false)
      this.validateDosage(false)
      this.validateMedication(false)
    } else if (rx_number == "") {
      patient_dob_label.classList.add("required-field")
      patient_name_label.classList.add("required-field")
      dosage_quantity_label.classList.add("required-field")
      medication_name_label.classList.add("required-field")
      // Update required list
      this.addRequired(this.ERR.PATIENT_DOB)
      this.addRequired(this.ERR.PATIENT_NAME)
      this.addRequired(this.ERR.DOSAGE)
      this.addRequired(this.ERR.MEDICATION)
      // Validate values
      this.validatePatientDOB(false)
      this.validatePatientName(false)
      this.validateDosage(false)
      this.validateMedication(false)
    }
  }

  addRequired(field) {
    if (!this.not_required.includes(field)) {
      this.not_required.push(field)
    }
  }

  removeRequired(field) {
    if (this.not_required.includes(field)) {
      this.removeValueFromArray(this.not_required, field)
    }
  }

	checkForm(e) {
    this.validateState()
    this.validatePharmacy()
    this.validateAddress()
    this.validateCity()
    this.validateZip()
    this.validatePhone()
    this.validatePersonSpoke()
    this.validatePatientName(false)
    this.validatePatientDOB(false)
    this.validateRxNumber(false)
    this.validatePatientPhone()
    this.validateMedication(false)
    this.validateDosage(false)
    this.validateGroup()
	  if (this.errors.length > 0) {
      e.preventDefault()
      scroll(0, 0)
    }
	}
}