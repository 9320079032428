import Validations from "./validations_controller"

export default class extends Validations {
  static targets = []

  connect() {
    super.connect()
  }

  initialize() {
    // Get the initial index of emails based on the last child in the div
    this.toIndex = parseInt(document.getElementById("to-emails").lastElementChild.id.slice(-1))
    this.ccIndex = parseInt(document.getElementById("cc-emails").lastElementChild.id.slice(-1))
    this.bccIndex = parseInt(document.getElementById("bcc-emails").lastElementChild.id.slice(-1))
  }

  createToInput(e) {
    e.preventDefault()
    this.toIndex++
    // create containing divs
    let container = document.createElement("div")
    container.classList += "form-group"
    container.id = `to_div_${this.toIndex}`

    // Add inner html
    container.innerHTML = `
    <div class="row">
      <div class="col-md-1 col">
        <button class="form_trash_button" type="button" data-action="admin-program-emails#deleteToInput">
          <i class="fas fa-trash" id="${this.toIndex}"></i>
        </button>
      </div>
      <div class="col-md-11 col-10">
        <input class="form-control" multiple="multiple" type="text" name="program_email[to][]" id="portal_to_${this.toIndex}">
      </div>
    </div>
    `
    // Append new objects to dom
    document.getElementById("to-emails").innerHTML += container.outerHTML
  }

  createCcInput(e) {
    e.preventDefault()
    this.ccIndex++
    // create containing divs
    let container = document.createElement("div")
    container.classList += "form-group"
    container.id = `cc_div_${this.ccIndex}`

    // Add inner html
    container.innerHTML = `
    <div class="row">
      <div class="col-md-1 col">
        <button class="form_trash_button" type="button" data-action="admin-program-emails#deleteCcInput">
          <i class="fas fa-trash" id="${this.ccIndex}"></i>
        </button>
      </div>
      <div class="col-md-11 col-10">
        <input class="form-control" multiple="multiple" type="text" name="program_email[cc][]" id="portal_cc_${this.ccIndex}">
      </div>
    </div>
    `
    // Append new objects to dom
    document.getElementById("cc-emails").innerHTML += container.outerHTML
  }

  createBccInput(e) {
    e.preventDefault()
    this.bccIndex++
    // create containing divs
    let container = document.createElement("div")
    container.classList += "form-group"
    container.id = `bcc_div_${this.bccIndex}`

    // Add inner html
    container.innerHTML = `
    <div class="row">
      <div class="col-md-1 col">
        <button class="form_trash_button" type="button" data-action="admin-program-emails#deleteBccInput">
          <i class="fas fa-trash" id="${this.bccIndex}"></i>
        </button>
      </div>
      <div class="col-md-11 col-10">
        <input class="form-control" multiple="multiple" type="text" name="program_email[bcc][]" id="portal_bcc_${this.bccIndex}">
      </div>
    </div>
    `
    // Append new objects to dom
    document.getElementById("bcc-emails").innerHTML += container.outerHTML
  }

  deleteToInput(e) {
    let to = document.getElementById(`to_div_${e.target.id}`)
    to.parentNode.removeChild(to)
  }

  deleteCcInput(e) {
    let cc = document.getElementById(`cc_div_${e.target.id}`)
    cc.parentNode.removeChild(cc)
  }

  deleteBccInput(e) {
    let bcc = document.getElementById(`bcc_div_${e.target.id}`)
    bcc.parentNode.removeChild(bcc)
  }
}