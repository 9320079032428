// Used to delete records from the admin panel
export function deleteRecord(url) {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Delete'
  }).then((result) => {
    if (result.isConfirmed) {
      $.ajax({
        type: "post",
        url: url,
        success: function (data) {
          toastr.success("Record deleted");
          window.location.reload()
        }
      })
    }
  })
}

export function deleteContact(url) {
  Swal.fire({
    title: 'Are you sure?',
    text: "If this contact is associated with a user, the user will be deleted as wll. You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Delete'
  }).then((result) => {
    if (result.isConfirmed) {
      window.location = url
    }
  })
}

