import Validations from "./validations_controller"

export default class extends Validations {
  static targets = [
    'programState',
    'programPrivate',
    'programTypeError',
    'program',
    'programError',
    'adsType',
    'webType',
    'stickersType',
    'printedType',
    'miscType',
    'otherType',
    'otherText',
    'graphicTypeError',
  ]

  connect() {
    super.connect()
    this.ERR.PROGRAM_TYPE = "programType"
    this.ERR.PROGRAM = "program"
    this.ERR.GRAPHIC = "graphic"

    this.MESSAGE.PROGRAM_TYPE = {
      EMPTY: "Program type is required"
    }
    this.MESSAGE.PROGRAM = {
      EMPTY: "Program is required if program type is state"
    }
    this.MESSAGE.GRAPHIC = {
      EMPTY: "Graphic type is required"
    }
  }

  validateProgramType() {
    if (!this.programStateTarget.checked && !this.programPrivateTarget.checked) {
      this.setError(this.ERR.PROGRAM_TYPE, this.programErrorTarget, this.MESSAGE.PROGRAM_TYPE.EMPTY)
    } else {
      this.removeError(this.ERR.PROGRAM_TYPE, this.programErrorTarget)
    }
  }

  toggleProgramSelect() {
    if (this.programStateTarget.checked) {
      this.programTarget.disabled = false
    } else if (this.programPrivateTarget.checked) {
      this.programTarget.disabled = true
    }
  }

  toggleGraphicType(e) {
    // Figure out the type of graphic selected
    let type = e.target.dataset.graphicRequestTarget.replace(/Type/g, "")
    if (type == "otherText") {
      this.otherTypeTarget.checked = true
    } else {
      this.otherTextTarget.value = ""
    }
  }

  validateProgram() {
    if (this.programStateTarget.checked) {
      this.checkValue(
        this.programTarget.value,
        this.programErrorTarget,
        this.ERR.PROGRAM,
        this.MESSAGE.PROGRAM
      )
    }
  }

  validateGraphic() {
    // We need to check and make sure that at least one graphic type is selected
    if (
      !this.adsTypeTarget.checked &&
      !this.webTypeTarget.checked &&
      !this.stickersTypeTarget.checked &&
      !this.printedTypeTarget.checked &&
      !this.miscTypeTarget.checked &&
      !this.otherTypeTarget.checked
    ) {
      this.setError(this.ERR.GRAPHIC, this.graphicTypeErrorTarget, this.MESSAGE.GRAPHIC.EMPTY)
    } else {
      this.removeError(this.ERR.GRAPHIC, this.graphicTypeErrorTarget)
    }
  }

  checkForm(e) {
    this.validateProgramType()
    this.validateProgram()
    this.toggleProgramSelect()
    this.validateEmail()
    this.validateName()
    //this.validateGraphic()
    if (this.errors.length > 0) {
      e.preventDefault()
      scroll(0, 0)
    }
  }
}