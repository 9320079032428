import Validations from "./validations_controller"

export default class extends Validations {
  static targets = [
    'priceTypeGreat',
    'priceTypePoor',
    'priceTypeError',
    'drugName',
    'drugNameError', 
    'drugDosage',
    'drugDosageError',
    'drugForm',
    'drugFormError',
    'drugQuantity',
    'drugQuantityError',
    'retailPrice',
    'retailPriceError',
    'discountPrice',
    'discountPriceError',
    'savingsPercentage',
    'savingsPercentageError',
    'chain',
    'other',
    'pharmacyChain',
    'pharmacyChainError',
    'pharmacyOther',
    'pharmacyNameError',
    'pharmacyOtherError',
  ]

  connect() {
    super.connect()
    this.ERR.STATE = "state"
    this.ERR.PRICE_TYPE = "priceType"
    this.ERR.DRUG_NAME = "drugName"
    this.ERR.DRUG_DOSAGE = "drugDosage"
    this.ERR.DRUG_FORM = "drugForm"
    this.ERR.DRUG_QUANTITY = "drugQuantity"
    this.ERR.RETAIL_PRICE = "retailPrice"
    this.ERR.DISCOUNT_PRICE = "discountPrice"
    this.ERR.SAVINGS_PERCENTAGE = "savingsPercentage"
    this.ERR.PHARMACY = "pharmacy"

    this.MESSAGE.STATE = {
      EMPTY: "State is required"
    }
    this.MESSAGE.PRICE_TYPE = {
      EMPTY: "Price type is required"
    }
    this.MESSAGE.DRUG_NAME = {
      EMPTY: "Drug name is required"
    }
    this.MESSAGE.DRUG_DOSAGE = {
      EMPTY: "Drug dosage is required",
      FORMAT: "Please only use numbers and decimals"
    }
    this.MESSAGE.DRUG_FORM = {
      EMPTY: "Drug form is required"
    }
    this.MESSAGE.DRUG_QUANTITY = {
      EMPTY: "Drug quantity is required",
      FORMAT: "Please only use whole numbers"
    }
    this.MESSAGE.RETAIL_PRICE = {
      EMPTY: "Retail price is required"
    }
    this.MESSAGE.DISCOUNT_PRICE = {
      EMPTY: "Discount price is required"
    }
    this.MESSAGE.SAVINGS_PERCENTAGE = {
      EMPTY: "Savings percentage is required"
    }
    this.MESSAGE.PHARMACY = {
      EMPTY: "Pharmacy name is required"
    }
  }

  validatePriceType() {
    let _greatChecked = this.priceTypeGreatTarget.checked
    let _poorChecked = this.priceTypePoorTarget.checked

    if (!_greatChecked && !_poorChecked) {
      this.setError(this.ERR.PRICE_TYPE, this.priceTypeErrorTarget, this.MESSAGE.PRICE_TYPE.EMPTY)
    } else {
      this.removeError(this.ERR.PRICE_TYPE, this.priceTypeErrorTarget)
    }
  }

  validateDrugName() {
    this.checkValue(
      this.drugNameTarget.value, 
      this.drugNameErrorTarget,
      this.ERR.DRUG_NAME,
      this.MESSAGE.DRUG_NAME
    )
  }

  validateDrugDosage() {
    this.checkValue(
      this.drugDosageTarget.value,
      this.drugDosageErrorTarget,
      this.ERR.DRUG_DOSAGE,
      this.MESSAGE.DRUG_DOSAGE
    )
  }

  validateDrugForm() {
    this.checkValue(
      this.drugFormTarget.value,
      this.drugFormErrorTarget,
      this.ERR.DRUG_FORM,
      this.MESSAGE.DRUG_FORM
    )
  }

  validateDrugQuantity() {
    this.checkValue(
      this.drugQuantityTarget.value,
      this.drugQuantityErrorTarget,
      this.ERR.DRUG_QUANTITY,
      this.MESSAGE.DRUG_QUANTITY
    )
  }

  validateRetailPrice() {
    this.checkValue(
      this.retailPriceTarget.value,
      this.retailPriceErrorTarget,
      this.ERR.RETAIL_PRICE,
      this.MESSAGE.RETAIL_PRICE
    )
  }

  validateDiscountPrice() {
    this.checkValue(
      this.discountPriceTarget.value,
      this.discountPriceErrorTarget,
      this.ERR.DISCOUNT_PRICE,
      this.MESSAGE.DISCOUNT_PRICE
    )
  }

  validateSavingsPercentage() {
    this.checkValue(
      this.savingsPercentageTarget.value,
      this.savingsPercentageErrorTarget,
      this.ERR.SAVINGS_PERCENTAGE,
      this.MESSAGE.SAVINGS_PERCENTAGE
    )
  }

  toggleCorrectPharmacy() {
    let _chainChecked = this.chainTarget.checked
    let _otherChecked = this.otherTarget.checked

    if (_otherChecked) {
      this.pharmacyChainTarget.disabled = true
      this.pharmacyOtherTarget.disabled = false
      this.removeError(this.PHARMACY, this.pharmacyNameErrorTarget)
      this.removeError(this.PHARMACY, this.pharmacyChainErrorTarget)
    } else if (_chainChecked) {
      this.pharmacyChainTarget.disabled = false
      this.pharmacyOtherTarget.disabled = true
      this.removeError(this.PHARMACY, this.pharmacyNameErrorTarget)
      this.removeError(this.PHARMACY, this.pharmacyOtherErrorTarget)
    }
  }

  checkpharmacyName() {
    let _chainChecked = this.chainTarget.checked
    let _otherChecked = this.otherTarget.checked

    if (_otherChecked) {
      this.checkValue(
        this.pharmacyOtherTarget.value,
        this.pharmacyOtherErrorTarget,
        this.ERR.PHARMACY,
        this.MESSAGE.PHARMACY
      )
    } else if (_chainChecked) {
      this.checkValue(
        this.pharmacyChainTarget.value,
        this.pharmacyChainErrorTarget,
        this.ERR.PHARMACY,
        this.MESSAGE.PHARMACY
      )
    } else if (!_otherChecked && ! _chainChecked) {
      this.setError(this.PHARMACY, this.pharmacyNameErrorTarget, this.MESSAGE.PHARMACY.EMPTY)
    }
  }


	checkForm(e) {
    this.validateName()
    this.validateEmail()
    this.validatePriceType()
    this.validateDrugName()
    this.validateDrugDosage()
    this.validateDrugForm()
    this.validateDrugQuantity()
    this.validateRetailPrice()
    this.validateDiscountPrice()
    this.validateSavingsPercentage()
    this.checkpharmacyName()
    this.validateAddress()
    this.validateCity()
    this.validateZip()
    if (this.errors.length > 0) {
      e.preventDefault()
      scroll(0, 0)
    }
  }
}