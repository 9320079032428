import Validations from "./validations_controller"

export default class extends Validations {
	static targets = [
		"program",
		"programError",
    "cities",
    "citiesError",
    "numberPharmacies",
    "numberPharmaciesError",
    "numberClinics",
    "numberClinicsError",
	]

	connect() {
		super.connect()
		this.ERR.PROGRAM = "program"
    this.ERR.CITIES = "cities"
    this.ERR.NUMBER_PHARMACIES = "numberPharmacies"
    this.ERR.NUMBER_CLINICS = "numberClinics"

		this.MESSAGE.PROGRAM = {
			EMPTY: "Please select a program"
		}
    this.MESSAGE.CITIES = {
      EMPTY: "Please list all cities visited"
    }
    this.MESSAGE.NUMBER_PHARMACIES = {
      EMPTY: "Please provide number of pharmacies"
    }
    this.MESSAGE.NUMBER_CLINICS = {
      EMPTY: "Please provide number of clinics"
    }
	}

	validateProgram() {
    this.checkValue(
      this.programTarget.value, 
      this.programErrorTarget,
      this.ERR.PROGRAM,
      this.MESSAGE.PROGRAM
    )
	}

  // TODO: Date

  validateCities() {
    this.checkValue(
      this.citiesTarget.value, 
      this.citiesErrorTarget,
      this.ERR.CITIES,
      this.MESSAGE.CITIES
    )
  }

  validateNumberPharmacies() {
    this.checkValue(
      this.numberPharmaciesTarget.value, 
      this.numberPharmaciesErrorTarget,
      this.ERR.NUMBER_PHARMACIES,
      this.MESSAGE.NUMBER_PHARMACIES,
      this.REGEX.INTEGER
    )
  }

  validateNumberClinics() {
    this.checkValue(
      this.numberClinicsTarget.value, 
      this.numberClinicsErrorTarget,
      this.ERR.NUMBER_CLINICS,
      this.MESSAGE.NUMBER_CLINICS,
      this.REGEX.INTEGER
    )
  }

	checkForm(e) {
  	this.validateName()
  	this.validateEmail()
  	this.validateProgram()
    this.validateCities()
    this.validateNumberPharmacies()
    this.validateNumberClinics()
	  if (this.errors.length > 0) {
      e.preventDefault()
      scroll(0, 0)
    }
	}
}