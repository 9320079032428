import Validations from "./validations_controller"

export default class extends Validations {
  static targets = ['issue', 'issueError']

  connect() {
    super.connect()
    this.ERR.ISSUE = "issue"
    this.MESSAGE.ISSUE = {
      EMPTY: "Issue is required"
    }
  }

	validateIssue() {
    this.checkValue(
      this.issueTarget.value,
      this.issueErrorTarget,
      this.ERR.ISSUE,
      this.MESSAGE.ISSUE
    )
	}

  checkForm(e) {
    this.validateName()
    this.validateEmail()
    this.validatePhone()
    this.validateIssue()
    if (this.errors.length > 0) {
      e.preventDefault()
      scroll(0, 0)
    }
  }
}