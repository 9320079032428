import Validations from "./validations_controller"

export default class extends Validations {
  static targets = [
    "phoneTemplate",
    "phoneContainer",
    "emailTemplate",
    "emailContainer"
  ]

  connect() {
    super.connect()
  }

  createPhoneInput(e) {
    e.preventDefault()
    let content = this.phoneTemplateTarget.innerHTML.replace(/NEW_PHONE/g, new Date().getTime())
    this.phoneContainerTarget.innerHTML += content
  }

  removePhoneInput(e) {
    e.preventDefault()
    let container = e.target.closest(".nested-phone-fields")
    this.removeInput(container, container.dataset.newPhoneRecord)
  }

  createEmailInput(e) {
    e.preventDefault()
    let content = this.emailTemplateTarget.innerHTML.replace(/NEW_EMAIL/g, new Date().getTime())
    this.emailContainerTarget.innerHTML += content
  }

  removeEmailInput(e) {
    e.preventDefault()
    let container = e.target.closest(".nested-email-fields")
    this.removeInput(container, container.dataset.newEmailRecord)
  }

  removeInput(container, dataset) {
    let wrapper = container
    if (dataset == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }
}