import Validations from "./validations_controller"

export default class extends Validations {
  static targets = [
    "username",
    "usernameError",
    "roles",
    "rolesError"
  ]

  connect() {
    super.connect()

    this.ERR.USERNAME = "username"
    this.ERR.ROLES = "roles"
    this.MESSAGE.USERNAME = {
      EMPTY: "Please provide a username"
    }
    this.MESSAGE.ROLES = {
      EMPTY: "At least one role is required"
    }
  }

  validateUsername() {
    this.checkValue(
      this.usernameTarget.value,
      this.usernameErrorTarget,
      this.ERR.USERNAME,
      this.MESSAGE.USERNAME,
      this.REGEX.NAME
    )
  }
}